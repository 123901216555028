<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">Nous contacter</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Accueil</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  
</section>

<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section pb-0">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Téléphone</h5>
            <!-- <p class="text-muted">Commencez à travailler avec SASI qui peut tout fournir</p> -->
            <a href="tel:+237675228541" class="text-primary">(+237) 675 228 541</a>
          </div>
        </div>
      </div>
      

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Email</h5>
            <!-- <p class="text-muted">Start working with Landrick that can provide everything</p> -->
            <a href="mailto:contact@sasi.pro" class="text-primary">contact@sasi.pro</a>
          </div>
        </div>
      </div>
      

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="card border-0 text-center features feature-clean">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="fw-bold">Localisation</h5>
            <p class="text-muted">Rue Njo-njo, <br>Bonapriso - Douala</p>
            <a (click)="mapView(content)" href="javascript:void(0);"
              data-type="iframe" class="video-play-icon text-primary">Voir sur Google map</a>
              <ng-template #content let-modal>
                <div class="modal-header">
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.964039308234!2d9.696545614707391!3d4.027754297070999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106112c069ed1617%3A0x96840cf49d108fe!2sRue%20Njo-Njo%2C%20Douala%2C%20Cameroun!5e0!3m2!1sfr!2sca!4v1633484314862!5m2!1sfr!2sca"
                    height="450" width="750">
                  </iframe>
                </div>
              </ng-template>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0">
          <div class="card-body py-5">
            <h4 class="card-title">Prenez contact avec nous !</h4>
            <div class="custom-form mt-3">
              <form action="#" method="post" enctype="multipart/form-data" [formGroup]="contactForm"
              (ngSubmit)="onSavecontact()">
                <p id="error-msg" class="mb-0"></p>
                <div id="simple-msg"></div>
                <div class="row">
                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Votre Nom <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input formControlName="name" name="name" id="name" class="form-control ps-5" placeholder="Nom :">
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input formControlName="email" name="email" id="email" class="form-control ps-5" placeholder="Email :">
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Téléphone </label>
                      <div class="form-icon position-relative">
                        <i-feather name="phone" class="fea icon-sm icons"></i-feather>
                        <input formControlName="phone" name="phone" id="phone" class="form-control ps-5" placeholder="Téléphone :">
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Objet</label>
                      <div class="form-icon position-relative">
                        <i-feather name="book" class="fea icon-sm icons"></i-feather>
                        <input formControlName="subject" name="subject" id="subject" class="form-control ps-5" placeholder="objet :">
                      </div>
                    </div>
                  </div>
                  

                  <div class="col-12">
                    <div class="mb-3">
                      <label class="form-label">Message <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                        <textarea formControlName="message" name="message" id="message" rows="4" class="form-control ps-5"
                          placeholder="Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="d-grid">
                      <button type="submit" id="submit" name="send" class="btn btn-primary" [disabled]="showMsg.loader">
                        <app-loader-button *ngIf="showMsg && showMsg.loader"></app-loader-button>
                        <span *ngIf="showMsg && !showMsg.loader">Envoyer Le Message</span>
                      </button>
                    </div>
                  </div>
                  
                </div>
                
              </form>
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      

      <div class="col-lg-7 col-md-6 order-1 order-md-2">
        <div class="card border-0">
          <div class="card-body p-0">
            <img src="assets/images/contact.svg" class="img-fluid" alt="">
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.964039308234!2d9.696545614707391!3d4.027754297070999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106112c069ed1617%3A0x96840cf49d108fe!2sRue%20Njo-Njo%2C%20Douala%2C%20Cameroun!5e0!3m2!1sfr!2sca!4v1633484314862!5m2!1sfr!2sca"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->