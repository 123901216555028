<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="about">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title">A propos de nous</h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Accueil</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">A propos de nous</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      
    </div>
    
  </div>
  
</section>

<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center" id="counter">
      <div class="col-md-6">
        <img src="assets/images/about/about.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="ms-lg-4">
          <div class="d-flex mb-4">
            <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="3" [from]="0"
                [duration]="4"></span>+</span>
            <span class="h6 align-self-end ms-2">Years <br> Experience</span>
          </div>
          <div class="section-title">
            <h4 class="title mb-4">Qui sommes nous ?</h4>
            <p class="text-muted"><span class="text-primary fw-bold">La société SASI (société Africaine de Sécurité et d’Instruments)</span>
              dans l’analyse et la maitrise de risque incendie, spécialisée dans le domaine de la protection des biens
              et des personnes par la sécurité électronique et incendie, ainsi que dans la distribution
               d’équipements industriels et pétroliers, notamment les équipements de sécurité en Atex.
               Nous vous proposons un large éventail de solutions. Nous réalisons la vente, l’installation
               et la maintenance de tous types de matériels de détection et d’extinction incendie,
               mais aussi des systèmes vidéo, contrôles d’accès, alarmes et domestique générale. Parallèlement,
               nous assurons les formations telles que, les formations EPI 1 et EPI 2 (Equipier de premier et seconde intervention)
               les formations en secourismes, les formations en Evacuation, les formations en environnement ATEX. Sans oublié
               les notices de sécurité et les études de dangers (…)  SASI intervient auprès des professionnels mais aussi
                des particuliers. Soucieux de la qualité de nos prestations nous nous engageons à vous fournir une installation
                parfaitement conforme aux Référentiels Français. Notre personnel est qualifié et possède une parfaite connaissance
                des normes et réglementations. Forts de cette expertise, nous sommes en mesure de répondre à toutes vos demandes,
                même les plus spécifiques. Nous avons à cœur de vous satisfaire en mettant à votre disposition notre expérience,
                notre professionnalisme et notre réactivité afin que vous puissiez améliorez votre sécurité</p>
            <a routerLink="/contact" class="btn btn-primary mt-3">Nous contacter</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-5">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->

<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h6 class="text-primary">Processus de Travail</h6>
          <h4 class="title mb-4">Comment nous travaillons ?</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Recueil des besoins et Inspection</h5>
            <!-- <p class="text-muted mb-0">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-airplay d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Stratégie & Réalisation</h5>
            <!-- <p class="text-muted mb-0">Generators convallis odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p> -->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div class="card features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-image-check d-block rounded h3 mb-0"></i>
          </div>

          <div class="card-body">
            <h5 class="text-dark">Livraison et validation</h5>
            <!-- <p class="text-muted mb-0">Internet Proin tempus odio, vel pharetra quam malesuada vel. Nam porttitor
              malesuada.</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->