import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})

/**
 * Page Service Component
 */
export class PageProjectsComponent implements OnInit {

  constructor() { }

  /**
   * Services Data
   */
  // projectsData = [];
  projectsData = [
    {
      image: `assets/images/projects/1 (1).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (4).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (5).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (6).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (7).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (9).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (11).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (12).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (14).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (15).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (13).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (10).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (8).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
    {
      image: `assets/images/projects/1 (3).jpeg`,
      title: "Sécurité incendie",
      localisation: "",
      date: "13 Septembre, 2021",
    },
  ];

  ngOnInit(): void {
  }

}
