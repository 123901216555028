const {createClient}  = require ('contentful-management');
const globalEnv    = require ('../../../environments/environment');
const CONFIG = {
  space: 'ysmozjnr2iss',
  environment: globalEnv.environment.netlifyEnv,
  accessToken: 'CFPAT-_KKFIj4LlR6lN2unUzk6_sZPHZy4lkT1tF0NPejTNvI',
  contentTypeIds: {
    newsletter: 'newsletter',
    contact: 'contact',
    member: 'member',
  }
}

const cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

const space = async () => {
  return await contentful.cdaClient.getSpace(contentful.CONFIG.space)
}

const environment = async () => { 
  let mySpace = await space(); 
  return await mySpace.getEnvironment(CONFIG.environment);
}

const contentful =  {
    cdaClient,
    space,
    environment,
    CONFIG
}

export default contentful;
