<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <a href="#" class="logo-footer">
                    <img src="assets/images/logo.png" height="50" alt="">
                </a>
                <p class="mt-4">Commencez à travailler avec SASI qui peut vous fournir tout ce dont vous avez besoin pour générer de la notoriété, générer du trafic, se connecter.</p>
                <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                    <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                            <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                        </a></li>
                </ul>
                <!--end icon-->
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="text-light footer-head">SASI</h5>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/about" class="text-foot"><i
                                class="uil uil-angle-right-b me-1"></i>
                            A propos de nous</a></li>
                    <li><a routerLink="/services" class="text-foot"><i
                                class="uil uil-angle-right-b me-1"></i>
                            Nos services</a></li>
                    <li><a routerLink="/projects" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                            Nos projets</a></li>
                    <li><a routerLink="/contact" class="text-foot"><i
                                class="uil uil-angle-right-b me-1"></i>
                            Contact</a></li>
                </ul>
            </div>
            <!--end col-->

            <!-- <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="text-light footer-head">Usefull Links</h5>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a routerLink="/page-terms" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                            Terms of Services</a></li>
                    <li><a routerLink="/page-privacy" class="text-foot"><i
                                class="uil uil-angle-right-b me-1"></i>
                            Privacy Policy</a></li>
                    <li><a routerLink="/documentation" class="text-foot"><i
                                class="uil uil-angle-right-b me-1"></i>
                            Documentation</a></li>
                    <li><a routerLink="/changelog" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                            Changelog</a></li>
                    <li><a routerLink="/components" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                            Components</a></li>
                </ul>
            </div> -->
            <!--end col-->

            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="text-light footer-head">Newsletter</h5>
                <p class="mt-4">Inscrivez-vous et recevez les derniers conseils par e-mail.</p>
                <form action="#" method="post" enctype="multipart/form-data" [formGroup]="newsLetterForm"
                (ngSubmit)="onSaveNewsletter()">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="foot-subscribe foot-white mb-3">
                                <label class="form-label">Ecrivez votre email <span
                                        class="text-danger">*</span></label>
                                <div class="form-icon position-relative">
                                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                    <input formControlName="email" type="email" name="email" id="emailsubscribe"
                                        class="form-control ps-5 rounded" placeholder="Votre email : " required>
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                        <div class="col-lg-12">
                            <div class="d-grid">
                                <!-- <input type="submit" id="submitsubscribe" name="send"
                                    class="btn btn-soft-primary" value="Souscrire"> -->
                                <button type="submit" id="submitsubscribe" name="send" class="btn btn-soft-primary" [disabled]="showMsg.loader">
                                    <app-loader-button *ngIf="showMsg && showMsg.loader"></app-loader-button>
                                    <span *ngIf="showMsg && !showMsg.loader">Souscrire</span>
                                </button>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                </form>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->
<footer class="footer footer-bar">
    <div class="container text-center">
        <div class="row align-items-center">
            <div class="col-sm-6">
                <div class="text-sm-start">
                    <p class="mb-0">©
                        <script type="text/javascript" id="www-widgetapi-script"
                            src="https://www.youtube.com/s/player/5d56cf74/www-widgetapi.vflset/www-widgetapi.js"
                            async=""></script>
                        <script id="iframe_api" src="https://www.youtube.com/iframe_api"></script> {{year}}
                        SASI. Design with <i class="mdi mdi-heart text-danger"></i> by <a
                            href="javascript:void(0);" class="text-reset">SkyDev Solution</a>.
                    </p>
                </div>
            </div>
            <!--end col-->

            <!-- <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <ul class="list-unstyled text-sm-end mb-0">
                    <li class="list-inline-item"><a href="javascript:void(0)"><img
                                src="assets/images/payments/american-ex.png" class="avatar avatar-ex-sm"
                                title="American Express" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/discover.png" class="avatar avatar-ex-sm" title="Discover"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/master-card.png" class="avatar avatar-ex-sm"
                                title="Master Card" alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/paypal.png" class="avatar avatar-ex-sm" title="Paypal"
                                alt=""></a></li>
                    <li class="list-inline-item ms-1"><a href="javascript:void(0)"><img
                                src="assets/images/payments/visa.png" class="avatar avatar-ex-sm" title="Visa"
                                alt=""></a></li>
                </ul>
            </div> -->
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</footer>
<!--end footer-->