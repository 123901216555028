// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  phoneNumber: "(+237) 675 228 541",
  phoneNumberHref: "tel:+237675228541",
  email: "contact@sasi.pro",
  support: "app.sasi2021@gmail.com",
  adress: "Rue Njo-njo, Bonapriso - Douala",
  UserIdEmailJs: "user_URKinZzEEIO0PB4XflcFD",
  ServiceIdEmailJs: "service_8byshjp",
  SenderTemplateEmailJs: "template_cymddpz",
  RecieverTemplateEmailJs: "template_yudtbsi",
  netlifyEnv: 'develop',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
