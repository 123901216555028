import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './core/pages/index/index.component';
import { PageContactComponent } from './core/pages/contact/contact.component';
import { MasterPageComponent } from './core/pages/master-page/master-page.component';
import { combineLatest } from 'rxjs/internal/operators';
import { PageServicesComponent } from './core/pages/services/services.component';
import { PageProjectsComponent } from './core/pages/projects/projects.component';
import { PageAboutComponent } from './core/pages/about/about.component';
import { PageServiceDetailsComponent } from './core/pages/services/details/details.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: 'index', component: IndexComponent },
      { path: 'about', component: PageAboutComponent },
      { path: 'contact', component: PageContactComponent },
      { path: 'services', component: PageServicesComponent },
      { path: 'projects', component: PageProjectsComponent },
      { path: 'services/details/:type', component: PageServiceDetailsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
