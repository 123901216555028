<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 text-center">
          <div class="page-next-level">
            <h4 class="title"> Services </h4>
            <div class="page-next">
              <nav aria-label="breadcrumb" class="d-inline-block">
                <ul class="breadcrumb bg-white rounded shadow mb-0">
                  <li class="breadcrumb-item"><a routerLink="/index">Accueil</a></li>
                  <li class="breadcrumb-item" aria-current="page"><a routerLink="/services">Services</a></li>
                  <li class="breadcrumb-item active" aria-current="page">{{serviceType}}</li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Hero End -->
  
  <!-- Shape Start -->
  <div class="position-relative">
    <div class="shape overflow-hidden text-white">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div>
  <!--Shape End-->
  
  <!-- Feature Start -->
  <section class="section">
    <div class="container">
      <!-- <app-services [servicesData]="servicesData"></app-services> -->
    <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10">
          <div class="section-title">
            <div class="text-center">
              <h4 class="title mb-4">{{title}}</h4>
              <!-- <img src="assets/images/blog/05.jpg" class="img-fluid rounded-md shadow-md" alt=""> -->
            </div>
            <ng-container *ngIf="serviceType == 'incendie'">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Système de sécurité adressable et conventionnel</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Système d’extinction à gaz inerte, sprinklage, brouillard d’eau et à mousse</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Moyens de secours: extincteur et RIA</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="serviceType == 'electronique'">
                <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Système de vidéo surveillance</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Alarme anti-intrusion</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Control d’accès</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Système de détection de fuite d’eau et flux d’eau</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="serviceType == 'formation'">
                <p class="text-muted mb-2">SASI propose des formations conformément aux préconisations de l’institut national de recherche et
                    de sécurité (INRS) et aux dispositions du code du travail comme notamment la formation SST et EPI.Ces formations de sécurité
                    incendie sur les lieux de travail sont de la responsabilité des chefs d’établissement et des employeurs.
                    SASI réalise ces formations obligatoires sous forme de formations initiales et/ou de recyclage.</p>
                <p class="text-muted mb-2">SASI dispense les formations suivantes:</p>
                <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation et recyclage Sauveteur Secouriste du Travail (SST)</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Equipier de 1ère intervention</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Exercices d’évacuation (Code du travail, ERP et IGH)</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation des chefs d’établissement à la sécurité incendie (ERP, code du travail, IGH, etc.)</li>
                </ul>
                <!-- <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation EPI 1 et EPI2 (équipier de premier et seconde intervention)</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation évacuation</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation SST (secouriste sauveteur au travail)</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Formation ATEX (Atmosphère explosive)</li>
                </ul> -->
            </ng-container>
            <ng-container *ngIf="serviceType == 'etude'">
                <p class="text-muted">Bureau d'études en prévention et sécurité incendie depuis plus de 5 ans,
                    SASI accompagne les maitres d’ouvrages et/ou architectes lors des travaux neufs,
                    des aménagements ou de transformation sur tous types de bâtiments.
                    Le rôle de SASI en la matière consiste à réaliser des </p>
                <p class="text-muted mb-2">Le rôle de SASI en la matière consiste à réaliser des:</p>
                <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Audits, diagnostics et expertises de tous types de bâtiments (ERP, Habitation, code du travail, IGH, etc.)
                  </li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Notice de sécurité</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Conseils, assistances et études (de la conception à l'exploitation) en prévention et sécurité incendie</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Pré-commissions de sécurité en prévention incendie.</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Accompagnements des architectes ou MOA (AMO).</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Assistances à la maitrise d’ouvrage (MOA) et maitrise d’œuvre (MOE) en prévention et sécurité incendie.</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Conseils et études accessibilité PMR.</li>
                </ul>
            </ng-container>
            <ng-container *ngIf="serviceType == 'ssi'">
                <p class="text-muted">SASI assure les missions de coordinateur de systèmes de sécurité incendie (SSI) en réalisant
                    entre autres des études, du conseil et de la coordination SSI de la conception à la réception des bâtiments.
                    Ces missions de coordination SSI sont obligatoires pour répondre aux dispositions de la règlementation
                    notamment lors des travaux d’aménagements ou neufs.</p>
                <p class="text-muted mb-2">Les coordinateurs SSI de SASI sont chargés de:</p>
                <ul class="list-unstyled text-muted">
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    L’analyse des besoins de mise en sécurité et définir les fonctions de mise en sécurité qui seront assignées au SSI
                  </li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    L’étude des dossiers lors de la conception.</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    La définition des zones de mise en sécurité et implantation des matériels (désenfumage, alarme, détection etc.).</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    La veille réglementaire et le contrôle du respect des normes en vigueur.</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    Choix des SSI selon les catégories des bâtiments (ERP, IGH, code du travail).</li>
                  <li class="mb-0"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                    La rédaction du dossier d'identité des SSI.</li>
                </ul>
            </ng-container>
            <!--end row-->
          </div>
        </div>
        <!--end col-->
      </div>
    </div>
    <!--end container-->
  
  </section>
  <!--end section-->
  <!-- Feature Start -->

  <!-- Project End -->
  
  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->