import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'src/app/services/contact/contact.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/***
 * Footer Component
 */
export class FooterComponent implements OnInit {

  @Input() footerVariant: string;
  @Input() hideFooter: boolean;

  //Get Year
  year = new Date().getFullYear()
  public newsLetterForm: FormGroup = new FormGroup({email: new FormControl()});
  
  public envVar: any;

  public showMsg: any = {
    success: null,
    error: null,
    msg: "",
    loader: false
  };
  
  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private toastr: ToastrService ) { 
    this.envVar = environment;
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.newsLetterForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])]
    });
  }

  async onSaveNewsletter(){
    try {
      this.initShowMsg();
      if(!this.newsLetterForm.invalid){
        this.showMsg.loader = true;
        let email = this.newsLetterForm.get('email')?.value;
        let [result, error] = await this.contactService.saveEmail(email);
        if(result){
          this.showMsg.success = true;
          this.showMsg.msg = "Enregistrement avec succes!";
          this.toastr.success("Votre email a été enregistré avec succès!", 'Newsletter',  {
            timeOut: 10000,
          });
          this.resetForm();
        } else {
          this.showError("Erreur: " + error?.message);
          this.toastr.error("Une erreur est survenue lors de l'enregistrement. Essayez à nouveau et contactez le support si le problème persiste!", 'Support: ' + environment.support,  {
            timeOut: 15000,
          });
        }
      } else {
        this.showError("Email invalide");
        this.toastr.error("Email invalide.", 'Newsletter',  {
          timeOut: 20000,
        });
      }
      
    } catch (error) {
      this.showError("Erreur: " + error?.message);
      this.toastr.error("Une erreur est survenue lors de l'enregistrement. Essayez à nouveau et contactez le support si le problème persiste!", 'Support: ' + environment.support,  {
        timeOut: 15000,
      });
    }
  }

  showError(msg: string){
    this.showMsg.error = true;
    this.showMsg.msg = msg;
    this.showMsg.loader = false;
  }

  initShowMsg(){
    this.showMsg = {
      success: null,
      error: null,
      msg: ""
    };
    this.showMsg.loader = false;
  }
  resetForm() {
    this.newsLetterForm.reset();
    this.showMsg.loader = false;
  }

}
