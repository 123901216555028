<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Services </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Accueil</a></li>
                <li class="breadcrumb-item active" aria-current="page">Services</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <!-- <app-services [servicesData]="servicesData"></app-services> -->
    <div class="row">
      <div class="col-md-6 col-12 mt-5">
        <div class="features features text-center pointer" (click)="navigateTo('incendie')">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-fire h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Sécurité incendie</h5>
              <p class="text-muted mb-0">Système de sécurité adressable et conventionnel</p>
              <p class="text-muted mb-0">Système d’extinction à gaz inerte, sprinklage...</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <div class="features features text-center pointer" (click)="navigateTo('electronique')">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-plug h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Sécurité électronique</h5>
              <p class="text-muted mb-0">Système de vidéo surveillance</p>
              <p class="text-muted mb-0">Alarme anti-intrusion...</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <div class="features features text-center pointer" (click)="navigateTo('formation')">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-book h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Formation</h5>
              <p class="text-muted mb-0">SASI propose des formations conformément aux préconisations de l’institut national
                de recherche et de sécurité (INRS) et aux...</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <!-- (click)="navigateTo('notice')" -->
        <div class="features features text-center pointer">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-exclamation-triangle h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Notice de sécurité et études de danger</h5>
              <p class="text-muted mb-0">Rédacteur des études notices de sécurité, études de danges, poi.</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <div class="features features text-center pointer" (click)="navigateTo('ssi')">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-vector-square h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Cordination SSI</h5>
              <p class="text-muted mb-0">SASI assure les missions de coordinateur de systèmes de sécurité incendie (SSI) en
                réalisant entre autres des études, du conseil et de la coordination SSI de la conception à la réception des bâtiments... </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-5">
        <div class="features features text-center pointer" (click)="navigateTo('etude')">
          <div class="image position-relative d-inline-block">
              <i class="uil uil-notes h1 text-primary"></i>
          </div>

          <div class="content mt-4">
              <h5>Etudes/Conseil</h5>
              <p class="text-muted mb-0">Bureau d'études en prévention et sécurité incendie depuis plus de 5 ans,
                SASI accompagne les maitres d’ouvrages et/ou architectes lors des travaux neufs... </p>
          </div>
        </div>
      </div>
    <!--end col-->
    </div>
  </div>
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Commencez à travailler avec Landrick <span
              class="text-primary fw-bold">SASI</span> qui peut vous fournir tout ce dont vous avez besoin pour générer la sécurité.</p>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
      </div>
    </div>
  </div> -->
  <!--end container-->
</section>
<!--end section-->
<!-- Feature Start -->

<!-- Project Start -->
<!-- <section class="section bg-light">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Une seule phrase: </h4>
          <p class="text-muted para-desc mx-auto">Commencez à travailler avec <span class="text-primary fw-bold">SASI</span>
            qui peut vous fournir tout ce dont vous avez besoin pour générer la sécurité.</p>
        </div>
      </div>
    </div>
  </div>
</section> -->
<!--end section-->
<!-- Project End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->