import { Injectable } from '@angular/core';
import contentful  from '../contentful/contentful.service';
import emailjs, { init } from 'emailjs-com';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor() {
    init(environment.UserIdEmailJs);
  }

  async saveEmail(email: string): Promise<any>{
    try {
      const data = {
        fields: {
          email: {
            'en-US': email
          }
        }
      };

      let env = await contentful.environment();

      let entry = await env.createEntry(contentful.CONFIG.contentTypeIds.newsletter, data)
            
      entry?.publish();
      return [true, null];
        
    } catch (error) {
      return [null, error];
    }
  }

  async saveContact(contact: any): Promise<any>{
    try {
      const data = {
        fields: {
          name: {
            'en-US': contact.name
          },
          phone: {
            'en-US': contact.phone
          },
          email: {
            'en-US': contact.email
          },
          message: {
            'en-US': contact.message
          },
        }
      };

      let env = await contentful.environment();
      let entry = await env.createEntry(contentful.CONFIG.contentTypeIds.contact, data)
      entry?.publish();
      
      return [entry, null];
        
    } catch (error) {
      return [null, error];
    }
  }

  async sendMail(to_name: string, from_name: string, sender: string, reciever:string,
    message: string, isReciever: boolean = true):Promise<any> {
    try {
      var templateParams = {
        to_name,
        from_name,
        sender,
        reciever,
        message,
        phone: environment.phoneNumber
      };
    
      let response = await emailjs.send(environment.ServiceIdEmailJs, 
        isReciever ? environment.RecieverTemplateEmailJs : environment.SenderTemplateEmailJs, templateParams);

      return [response, null];
      
    } catch (error) {
      console.log('FAILED...', error);
      return [null, error];
    }
  }
}
