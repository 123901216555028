<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Projets </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Accueil</a></li>
                <li class="breadcrumb-item active" aria-current="page">Projets</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    
  </div>
  
</section>

<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->
<!-- Project Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Quelques images de nos interventions chez les clients</h4>
          <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2" *ngFor="let p of projectsData">
        <div class="card work-container work-modern position-relative overflow-hidden rounded border-0">
          <div class="card-body p-0">
            <img [src]="p.image" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <a routerLink="/portfolio-detail-one" class="title text-white d-block fw-bold">{{p.title}}</a>
              <small class="text-light">{{p.localisation}}</small>
            </div>
            <div class="client">
              <small class="text-light user d-block"><i class="mdi mdi-account"></i> SASI</small>
              <small class="text-light date"><i class="mdi mdi-calendar-check"></i> {{p.date}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  
</section>


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->