import { Component, OnInit } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/partners/A2S.png"
    },
    {
      image: "assets/images/partners/BRASAF.jpg"
    },
    {
      image: "assets/images/partners/BRASSERIE DU MALI.jpg"
    },
    {
      image: "assets/images/partners/ECOPROTECTION.jpg"
    },
    {
      image: "assets/images/partners/FINSECUR.png"
    },
    {
      image: "assets/images/partners/GRUNDFOS.jpg"
    },
    {
      image: "assets/images/partners/KRYSTAL PALACE.png"
    },
    {
      image: "assets/images/partners/PLASTICAM.jpg"
    },
    {
      image: "assets/images/partners/POK.png"
    },
    {
      image: "assets/images/partners/ROT.png"
    },
    {
      image: "assets/images/partners/WILO.png"
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
