import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { ContactService } from 'src/app/services/contact/contact.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-page-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

/**
 * Page Contact-One Component
 */
export class PageContactComponent implements OnInit {

  public contactForm: FormGroup;
  
  public envVar: any;

  public showMsg: any = {
    success: null,
    error: null,
    msg: "",
    loader: false
  };
  
  constructor(private modalService: NgbModal, private formBuilder: FormBuilder, private contactService: ContactService
    , private toastr: ToastrService ) { 
    this.envVar = environment;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      phone: [''],
      subject: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      message: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
    });
  }

  async onSavecontact(){
    try {
      debugger;
      this.initShowMsg();
      if(!this.contactForm.invalid){
        this.showMsg.loader = true;
        let contact = {
          name: this.contactForm.get('name')?.value,
          subject: this.contactForm.get('subject')?.value,
          email: this.contactForm.get('email')?.value,
          phone: this.contactForm.get('phone')?.value,
          message: this.contactForm.get('message')?.value
        };
        let [result, error] = await this.contactService.saveContact(contact);

        if(result){
          this.showMsg.success = true;
          this.toastr.success("Message envoyé avec succès!", 'Contact',  {
            timeOut: 10000,
          });
          this.showMsg.loader = false;
          this.resetForm();
        } else {
          this.showError("Erreur: " + error?.message);
          this.toastr.error("Une erreur est survenue lors de l'envoi. Essayez à nouveau et contactez le support si le problème persiste!", 'Support: ' + environment.support,  {
            timeOut: 15000,
          });
        }
      } else {
        this.showError("Champ invalide");
        this.toastr.error("Vérifiez si tous les champs ont été correctement rempli svp!", 'Champs invalides',  {
          timeOut: 15000,
        });
      }
      
    } catch (error) {
      this.showError("Erreur: " + error?.message);
      this.toastr.error("Une erreur est survenue lors de l'envoi. Essayez à nouveau et contactez le support si le problème persiste!", 'Support: ' + environment.support,  {
        timeOut: 15000,
      });
    }
  }

  showError(msg: string){
    this.showMsg.error = true;
    this.showMsg.msg = msg;
    this.showMsg.loader = false;
  }

  initShowMsg(){
    this.showMsg = {
      success: null,
      error: null,
      msg: "",
      loader: false
    };
  }
  resetForm() {
    this.contactForm.reset();
  }

  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }
}
