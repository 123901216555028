import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class PageServiceDetailsComponent implements OnInit {
  serviceType = '';
  title = '';
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.serviceType = params['type'];
    })
  }

  ngOnInit(): void {
    console.log(this.serviceType)
    this.setTitle();
  }

  setTitle(){
    switch(this.serviceType){
      case 'incendie':
        this.title = 'Sécurité incendie';
      break;
      case 'electronique':
        this.title = 'Sécurité électronique';
      break;
      case 'formation':
        this.title = 'Formation';
      break;
      case 'notice':
        this.title = 'Notice de sécurité et études de danger';
      break;
      case 'ssi':
        this.title = 'Cordination SSI';
      break;
      case 'etude':
        this.title = 'Etudes/Conseil';
      break;
      default:
        this.title = '';
    }
  }

}
