<section class="bg-half-170 d-table w-100">
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="col-lg-7 col-md-7">
        <div class="title-heading me-lg-4">
          <h1 class="heading mb-3">Upgrate your  <span class="text-primary">Security</span> </h1>
          <p class="para-desc text-muted">La société SASI (société Africaine de Sécurité et d’Instruments) dans l’analyse et
            la maitrise de risque incendie, spécialisée dans le domaine de la protection des biens et des personnes par
             la sécurité électronique et incendie, ainsi que dans la distribution d’équipements industriels et pétroliers,
             notamment les équipements de sécurité en Atex.</p>
          <div class="mt-4">
            <!-- <a routerLink="/page-contact-one" class="btn btn-primary mt-2 me-2"><i class="uil uil-envelope"></i> Get
              Started</a> -->
            <a routerLink="/about" class="btn btn-outline-primary mt-2"><i class="uil uil-book-alt"></i>
              En savoir plus</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/illustrator/Startup_SVG.svg" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 border-bottom border-top">
  <div class="container">
    <app-clients-logo></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- How It Work Start -->
<section class="section bg-light border-bottom">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Nos offrons une multitude de services</h4>
          <!-- <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2">
        <img src="assets/images/illustrator/SEO_SVG.svg" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Nous combinons notre savoir faire et notre expertise pour vous satisfaire</h4>
          <p class="text-muted">Nos services:</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Sécurité incendie</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Sécurité électronique</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Formation</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Notice de sécurité et études de danger</li>
          </ul>
          <a routerLink="/services"  class="mt-3 h6 text-primary">Pour en savoir plus <i
              class="uil uil-angle-right-b align-middle"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!--end container-->
</section>
<!--end section-->
<!-- How It Work End -->

<!-- Testi Start -->
<section class="section pb-0">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h6>Nous croyons qu'il faut se construire mutuellement</h6>
          <h4 class="title mb-4"> et donc nous travaillons avec des partenaires exceptionnels</h4>
          <!-- <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p> -->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <!-- <img src="assets/images/client/amazon.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt=""> -->
                <p class="text-muted mt-4">"J'apprécie leur professionnalisme et leur communication avec le client."</p>
                <h6 class="text-primary">- Kamga Jean</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <!-- <img src="assets/images/client/google.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt=""> -->
                <p class="text-muted mt-4">"Respect des délais et de la qualité. Ils s'y connaissent"</p>
                <h6 class="text-primary">- Eboumbou Franck</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <!-- <img src="assets/images/client/lenovo.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt=""> -->
                <p class="text-muted mt-4">"Ils ont fait preuve de dynamisme et de spontaneité"</p>
                <h6 class="text-primary">- Barbara Tchuenté</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body">
                <!-- <img src="assets/images/client/paypal.svg" class="img-fluid avatar avatar-ex-sm mx-auto" alt=""> -->
                <p class="text-muted mt-4">"Merci pour votre assistance et disponibilité"</p>
                <h6 class="text-primary">- Elyse Somen</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Testi End -->

<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Pricing End -->

<!-- FAQ n Contact Start -->
<section class="section bg-light">
  <div class="container">

    <div class="row my-md-5 pt-md-3 my-4 pt-2 pb-lg-4 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Avez-vous des questions ?</h4>
          <!-- <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate awareness, drive traffic, connect.</p> -->
          <a routerLink="/contact" class="btn btn-primary mt-4"><i class="mdi mdi-phone"></i> Contactez-nous!</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- FAQ n Contact End -->


<!-- Back to top -->
<a href="#" [ngxScrollTo]="'#home'" id="back-to-top" class="btn btn-icon btn-primary back-to-top">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->